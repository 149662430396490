<template>
    <v-dialog v-model="dialog" width=500>
        <v-card>
            <v-form ref="form" v-model="formValid">

                <v-toolbar color="primary" dark dense>
                    <v-toolbar-title>Agregar nuevo insumo</v-toolbar-title>
                    <v-spacer/>
                    <v-btn text @click="cancela">
                        <v-icon>mdi-close-box</v-icon>
                    </v-btn>
                </v-toolbar>

                <v-card-text>
                    Contenido de la ventana
                </v-card-text>

                <v-card-actions>
                    <v-spacer/>

                    <v-btn color="success" @click="guarda" :loading="cargando">
                        Agregar
                    </v-btn>
                </v-card-actions>
            </v-form>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props:{
        dialogModal: Boolean,
    },

    data:()=>({
        formValid:false,
        cargando:false,
    }),

    methods:{
        async guarda(){
            this.dialog=false
        },
        cancela(){
            this.dialog=false
        },
    },

    computed:{
        dialog:{
            get(){return this.dialogModal},
            set(val){this.$emit('update:dialogModal',val)}
        }
    },
    watch:{
        dialog(v){
            if (!v){
                this.$refs.form.reset()
            }
        }
    },
}
</script>

<style>

</style>